module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"quality":100,"showCaptions":true},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":"100","maintainCase":false,"removeAccents":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P87HT6H","includeInDevelopment":false,"addTagInBody":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#fd9927"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Big Tits Webcam Girls","short_name":"Big Tits Webcam Girls","description":"A curated list of big tits camgirls","start_url":"/","background_color":"#000","theme_color":"#fd9927","display":"minimal-ui","icons":[{"src":"/logo/logo-48.png","sizes":"48x48","type":"image/png"},{"src":"/logo/logo-1024.png","sizes":"1024x1024","type":"image/png"}]},
    }]
