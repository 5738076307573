// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-tsx": () => import("../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-article-tsx": () => import("../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-model-room-tsx": () => import("../src/templates/ModelRoom.tsx" /* webpackChunkName: "component---src-templates-model-room-tsx" */),
  "component---src-templates-category-tsx": () => import("../src/templates/Category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-advent-calendar-2020-tsx": () => import("../src/pages/advent-calendar/2020.tsx" /* webpackChunkName: "component---src-pages-advent-calendar-2020-tsx" */),
  "component---src-pages-blog-tsx": () => import("../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-categories-tsx": () => import("../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */),
  "component---src-pages-search-tsx": () => import("../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */)
}

